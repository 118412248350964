<template>
    <div class="box">
        <div class="contents2">
            <div class="ls-list" v-for="item in wpgList" :key="item">
                <div class="item-top">
                    <div style="display: flex;align-items: center;">
                        <img
                            src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIukeGMeadkSdvLAPLHbqBM8WjiaN8e9YW0B0eV0vPIoEdiaRhibrnicmLu589ex30AptamKl4h6BQH2g/132"
                            style="width: 24px;height: 24px;border-radius: 50%;margin-right: 8px;"/>
                        <div>{{item.name}} {{item.age}}岁 {{item.sex == 1 ? '男' : '女'}}</div>
                        <i
                            class="iconfont icon-huangguan"
                            style="color: #f3c32e;font-size: 25px;margin-left: 8px;"></i>
                    </div>
                    <div style="font-size: 14px;color: #9A9B9E;">
                        <span style="color:#00AC97;">24</span>
                        <img style="width:16px;height:16px;margin-left:5px;" :src="item.barcode" alt="">
                    </div>
                </div>
                <div style="padding: 15px 0; font-size: 14px; color: #333333;line-height: 22px;">
                    <div>
                        进入病区时间：2022-08-15 14
                    </div>
                    <div>
                        联系电话：1371213119
                    </div>
                    <div style="display: flex;align-items: center;justify-content: space-between;">
                        <div>病人：23床 刘德华 60岁 男 Ⅰ级护</div>
                        <div style="display: flex;align-items: center;">
                            <span>21</span>
                            <img style="width:16px;height:16px;margin-left:5px;" src="https://middle-ground.shulanhealth.com/lvma-icon.png" alt="">
                        </div>
                    </div>
                    <div>住院号：{{item.zyh}}</div>
                    <div>入院日期：{{ item.ryrq }}</div>
                </div>
                <!-- <div style="display: flex;align-items: center;justify-content: flex-end;color:#00AC97;">
                    <div class="now-pg" @click="nowPingGu(item)">不通过</div>
                    <div class="now-pg" @click="nowPingGu1(item)" style="margin-left:10px;background: #00AC97;color:#fff;">通过</div>
                </div> -->
            </div>
            <!-- <div
                class="load-more"
                @click="loadMore"
                v-if="selId == 1 && !showLoading && wpgList.length!==0"
                >
                {{ isOver ? "已加载全部数据" : "点击加载更多数据" }}
            </div> -->
            <empty :show="wpgList.length == 0 ? true: false"></empty>
        </div>
        <div class="search-box">
            <div class="search-input">
                <img
                    src="http://middle-ground.shulanhealth.com/f-fpjzxq-search.png"
                    alt=""
                />
                <input
                    type="text"
                    v-model="retrieve"
                    @input="searchBtn"
                    placeholder="推荐人姓名/病人姓名/联系电话快速查找"
                />
                <img
                    v-if="retrieve !== ''"
                    src="https://middle-ground.shulanhealth.com/clear-in.png"
                    alt=""
                    style="width: 13px; height: 13px"
                    class="clear-input"
                    @click="clearInput"
                />
            </div>
            <div class="search-btn" @click="searchBtn">搜索</div>
        </div>
        <van-popup v-model="showPingguPopup" position="bottom" @click-overlay="clickOverlay">
            <div style="width: 92%;margin-left:4%;">
                <div style="margin-top:15px;">
                    <div>保险产品类型*<span style="margin-left:5px;font-size:14px;color:#C0C0C0;">(可多选)</span></div>
                    <div class="list-box">
                        <div v-for="(item, index) in xzList" :key="index" class="sel-item" :class="[item.ischeck ? 'selbx' : '']" @click="selBx(item, index)">{{item.title}}</div>
                    </div>
                </div>
                <div style="margin-top:30px;" v-if="isTaikang && bhJbt">
                    <div>是否有健保通*</div>
                    <div class="list-box">
                        <div v-for="(item, index) in bjtList" :key="index" @click="selBjt(item, index)" style="margin-right:28px;">
                            <div class="redio-box">
                                <div class="waiquan" :class="[item.ischeck ? 'sel-redio' : '']">
                                    <div class="meiquan"></div>
                                </div>
                                <div>{{item.title}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top:30px;">
                    <div>客户类型*<span style="margin-left:5px;font-size:14px;color:#C0C0C0;">(重要客户提供陪诊服务)</span></div>
                    <div class="list-box">
                        <div v-for="(item, index) in khlxList" :key="index" @click="selKhlx(item, index)" style="margin-right:28px;">
                            <div class="redio-box">
                                <div class="waiquan" :class="[item.ischeck ? 'sel-redio' : '']">
                                    <div class="meiquan"></div>
                                </div>
                                <div>{{item.title}}</div>
                            </div>
                        </div>
                        <input type="text" style="margin-top:15px;border:none;width: 100%;height: 30px;" v-model="otherContent" placeholder="请输入" v-if="selItemId ==3">
                    </div>
                </div>
                <!-- <div class="bottom-btn" @click="sendJzsqAscy">评估通过</div> -->
                <div class="bottom-btn" @click="sendJzsqAscy" v-if="!showLoading">评估通过</div>
                <div class="bottom-btn" v-else>正在评估，请稍后...</div>
            </div>
        </van-popup>
        <loading :show="showLoading"></loading>
    </div>
</template>
<script>
import { Popup, Toast, Dialog } from "vant";
import Empty from "./comm/empty.vue";
import Loading from "./comm/loading.vue";
import { user } from "@/api";
export default {
    components: {
        VanPopup: Popup,
        Empty: Empty,
        Loading: Loading,
    },
    data() {
        return {
            active: 0,
            selId: 1,
            bhJbt: false,
            isTaikang: false,
            isUnFold: false,
            showBxgsPopup: false,
            showFzjgPopup: false,
            showPingguPopup: false,
            showLoading: false,
            wpgList: [
                {
                    barcode: 'https://middle-ground.shulanhealth.com/lvma-icon.png',
                    name: '颜艺',
                    age: '20',
                    sex: 2,
                    patient: '陈燕',
                    bed: 26,
                    patientAge: 60,
                    patientSex: 2,
                    hulidj: 'Ⅰ',
                    zyh: 60232355,
                    mobile: '13259872234',
                    ryrq: '2022-08-16',
                    sqsj: '2022-08-25 15:10'
                },{
                    barcode: 'https://middle-ground.shulanhealth.com/huangma-icon.png',
                    name: '颜艺',
                    age: '20',
                    sex: 2,
                    patient: '陈燕',
                    bed: 26,
                    patientAge: 60,
                    patientSex: 2,
                    hulidj: 'Ⅰ',
                    zyh: 60232355,
                    mobile: '13259872234',
                    ryrq: '2022-08-16',
                    sqsj: '2022-08-25 15:10'
                },{
                    barcode: 'https://middle-ground.shulanhealth.com/hongma-icon.png',
                    name: '颜艺',
                    age: '20',
                    sex: 1,
                    patient: '陈燕',
                    bed: 26,
                    patientAge: 60,
                    patientSex: 1,
                    hulidj: 'Ⅰ',
                    zyh: 60232355,
                    mobile: '13259872234',
                    ryrq: '2022-08-16',
                    sqsj: '2022-08-25 15:10'
                }
            ],
            ypgList: [
                {
                    barcode: 'https://middle-ground.shulanhealth.com/lvma-icon.png',
                    name: '颜艺',
                    status: 1,
                    age: '20',
                    sex: 2,
                    patient: '陈燕',
                    bed: 26,
                    patientAge: 60,
                    patientSex: 2,
                    hulidj: 'Ⅰ',
                    zyh: 60232355,
                    mobile: '13259872234',
                    ryrq: '2022-08-16',
                    sqsj: '2022-08-25 15:10'
                },{
                    barcode: 'https://middle-ground.shulanhealth.com/huangma-icon.png',
                    name: '颜艺',
                    status: 2,
                    age: '20',
                    sex: 2,
                    patient: '陈燕',
                    bed: 26,
                    patientAge: 60,
                    patientSex: 2,
                    hulidj: 'Ⅰ',
                    zyh: 60232355,
                    mobile: '13259872234',
                    ryrq: '2022-08-16',
                    sqsj: '2022-08-25 15:10'
                },{
                    barcode: 'https://middle-ground.shulanhealth.com/hongma-icon.png',
                    name: '颜艺',
                    status: 2,
                    age: '20',
                    sex: 1,
                    patient: '陈燕',
                    bed: 26,
                    patientAge: 60,
                    patientSex: 1,
                    hulidj: 'Ⅰ',
                    zyh: 60232355,
                    mobile: '13259872234',
                    ryrq: '2022-08-16',
                    sqsj: '2022-08-25 15:10'
                }
            ],
            bxgsObj: "",
            selItemId:"",
            fzjgObj: "",
            otherContent: "",
            bxgsCom: [],
            fzjgCom: [],
            xzList: [
                {
                    id: 1,
                    title: '医疗险',
                    ischeck: false
                },
                {
                    id: 2,
                    title: '重疾险',
                    ischeck: false
                },
                {
                    id: 3,
                    title: '无保险',
                    ischeck: false
                },
                {
                    id: 4,
                    title: '其他（含年金、寿险、意外险、津贴等）',
                    ischeck: false
                }
            ],
            khlxList: [
                {
                    id: 1,
                    title: '普通客户',
                    ischeck: false
                },
                {
                    id: 2,
                    title: '重要客户',
                    ischeck: false
                },
                {
                    id: 3,
                    title: '其他',
                    ischeck: false
                }
            ],
            bjtList: [
                {
                    id:1,
                    title: '是',
                    ischeck: false
                },
                {
                    id:2,
                    title: '否',
                    ischeck: false
                }
            ],
            retrieve: "",
            pageNo: 1,
            pageSize: 500,
            isOver: false,
            userName: "",
            customerType: "",
            insuranceType: [],
            itemInfo: "",
            jbtHave: false,
            isBjt: "否"
        }
    },
    filters: {
        qwyyFilter(value) {
            switch (value) {
                case "1":
                    return "树兰（杭州）医院";
                case "2":
                    return "树兰（安吉）医院";
                case "3":
                    return "树兰互联网医院";
                case "4":
                    return "树兰会";
                case "5":
                    return "树兰（衢州）医院";
            }
        },
        formatDateTime(date) {
            return date !== undefined && date !== null
                ? date.substring(0, 16)
                : "";
        },
    },
    mounted() {
        document.title = "陪护人员列表";
        // let token = JSON.parse(this.getQueryString("result")).result.token;
        // localStorage.setItem("token", token);
        // this.isTaikang = localStorage.getItem("companyid") == 18 ? true : false
        // this.getAssessmentListAscy()
    },
    methods: {
        bjtSwitch() {
            console.log(this.getInsuranceType().toString())
        },
        goDetail(e) {
            this.$router.push({
                name: "jzgc",
                query: {
                    action: "",
                    id: e.lsWriteContentId,
                    },
                });
        },
        searchBtn() {
            if(this.selId == 1) {
                this.getAssessmentListAscy()
            } else {
                this.getYAssessmentListAscy()
            }
        },
        selBar(e) {
            this.selId = e;
            // if(this.selId == 1) {
            //     this.getAssessmentListAscy()
            // } else {
            //     this.getYAssessmentListAscy()
            // }
        },
        clearInput() {
            this.retrieve = "";
        },
        nowPingGu(e) {
            // this.isTaikang = e.company == 18 ? true : false
            this.itemInfo = e
            Dialog.confirm({
            title: '提醒',
            confirmButtonColor: '#00AC97',
            message:
                '确定不通过吗？',
            })
            .then(() => {
                // on confirm
            })
            .catch(() => {
                // on cancel
            });
            // this.showPingguPopup = true;
        },
        nowPingGu1(e) {
            // this.isTaikang = e.company == 18 ? true : false
            this.itemInfo = e
            // this.showPingguPopup = true;
            Dialog.confirm({
            title: '提醒',
            confirmButtonColor: '#00AC97',
            message:
                '确定通过吗？',
            })
            .then(() => {
                // on confirm
            })
            .catch(() => {
                // on cancel
            });
        },
        clickOverlay() {
            this.showPingguPopup = false;
        },
        selBx(item, e) {
            if(this.xzList[e].ischeck == false) {
                this.xzList[e].ischeck = true
            } else {
                this.xzList[e].ischeck = false
            }
            if(item.title == '无保险') {
                this.xzList[e].ischeck = true
                this.xzList[0].ischeck = false
                this.xzList[1].ischeck = false
                this.xzList[3].ischeck = false
            } else {
                this.xzList[2].ischeck = false
            }
            this.bhJbt = this.getInsuranceType().toString().indexOf("医疗险") != -1
            // console.log(this.getInsuranceType().toString().indexOf("医疗险") != -1)
        },
        selKhlx(item, e) {
            this.customerType = item.title
            this.selItemId = this.khlxList[e].id
            for(let i in this.khlxList) {
                this.khlxList[i].ischeck = false
            }
            this.khlxList[e].ischeck = true
        },
        selBjt(item, e) {
            this.isBjt = item.title
            this.selisBjtId = this.bjtList[e].id
            for(let i in this.bjtList) {
                this.bjtList[i].ischeck = false
            }
            this.bjtList[e].ischeck = true
        },
        getQueryString: function (name) {
            let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
            let r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(decodeURI(r[2]));
            }
        },
        getInsuranceType() {
            let ls = []
            for(let i in this.xzList) {
                if(this.xzList[i].ischeck) {
                    ls.push(this.xzList[i].title)
                }
            }
            return ls
        },
        sendJzsqAscy() {
            if (this.getInsuranceType().toString() == "") {
                Toast("请选择保险类型");
                return;
            }
            if(this.isTaikang || this.bhJbt) {
                if(this.isBjt == '') {
                    Toast("请选择是否有健保通");
                    return;
                }
            }
            if (this.customerType == "") {
                Toast("请选择客户类型");
                return;
            }
            this.showLoading = true;
            let params = {
                id: this.itemInfo.id,
                customerType: this.customerType == '其他' ? this.customerType + ',' + this.otherContent : this.customerType,
                healthCarePass: (!this.isTaikang || !this.bhJbt) ? 0 : this.getJbt(),
                insuranceType: this.getInsuranceType().toString()
            };
            // console.log(params)
            user.sendJzsq(params).then((res) => {
                if (res.data.code == 200) {
                    this.showLoading = false;
                    this.showPingguPopup = false
                    Toast.success('评估成功');
                    this.getAssessmentListAscy()
                } else {
                    this.showLoading = false;
                    Toast.fail(res.data.message);
                }
            });
        },
        getJbt() {
            for(let i in this.bjtList) {
                if(this.bjtList[i].ischeck) {
                    return 1
                } else {
                    return 0
                }
            }
        },
        loadMore() {
            this.pageNo = this.pageNo + 1
            if(this.selId == 1) {
                this.getAssessmentListAscy()
            } else {
                this.getYAssessmentListAscy()
            }
        },
        getYAssessmentListAscy() {
            this.showLoading = true;
            let params = {
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                status: 3,
                retrieve: this.retrieve
            };
            // getAssessmentList
            user.getAssessmentList(params).then((res) => {
                if (res.data.code == 200) {
                    this.showLoading = false;
                    if(res.data.result.records.length == 0 && this.pageNo!== 1 ) {
                        this.isOver = true
                    } else {
                        this.isOver = false
                    }
                    this.ypgList = res.data.result.records;
                } else {
                    this.showLoading = false;
                    Toast.fail(res.data.message)
                }
            });
        },
        getAssessmentListAscy() {
            this.showLoading = true;
            let params = {
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                status: 2,
                retrieve: this.retrieve
            };
            user.getAssessmentList(params).then((res) => {
                if (res.data.code == 200) {
                    this.showLoading = false;
                    if(res.data.result.records.length == 0 && this.pageNo!== 1 ) {
                        this.isOver = true
                    } else {
                        this.isOver = false
                    }
                    this.wpgList = res.data.result.records;
                } else {
                    this.showLoading = false;
                    Toast.fail(res.data.message)
                }
            });
        },
        noPassByMobile(str) {
            if(null != str && str != undefined) {
                var pat=/(\d{3})\d*(\d{4})/;
                return str.replace(pat,'$1****$2');
            } else {
                return "";
            }
        },
        noPassByName(str) {
            if(null != str && str != undefined){
                if(str.length <= 3){
                    return str.substring(0,1) + "*" + str.substring(2,str.length);
                } else if(str.length > 3 && str.length <= 6){
                    return str.substring(0,1) + "**" + str.substring(2,str.length);
                } else if(str.length > 6){
                    return str.substring(0,2) + "****" + str.substring(6,str.length)
                }
            } else {
                return "";
            }
        },
        noPassByidCard(str) {
            return str.replace(/^(.{6})(?:\d+)(.{4})$/, "$1****$2");
        },
    }
};
</script>
<style scoped>
.right-arrow {
    width: 7px;
    height: 7px;
    border-top: 1px solid rgb(75, 119, 175);
    border-right: 1px solid rgb(75, 119, 175);
    transform: rotate(45deg);
}
.load-more {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.redio-box {
    display: flex;
    align-items: center;
}
.waiquan {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #C0C0C0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}
.meiquan {
    background: #fff;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}
.sel-redio {
    background: #00AC97;
}
.box {
    width: 100%;
    background-color: #f5f5f5;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    /* padding-bottom: 10px; */
}
.sel {
    background: #ffffff;
    color: #333333;
}
.no-sel {
    background: rgba(255, 255, 255, 0.4);
    color: #9a9b9e;
}
.top-bar {
    width: 100%;
    /* margin-left: 3%; */
    /* margin-top: 10px; */
    display: flex;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background: #F5F5F5;
    z-index: 1;
}
.bar-item {
    height: 44px;
    width: 100%;
    border-radius: 8px 0px 0px 8px;
    font-size: 15px;
    text-align: center;
    line-height: 44px;
    position: relative;
    display: flex;
    justify-content: center;
}
.bar-item1 {
    height: 44px;
    width: 100%;
    border-radius: 0px 8px 8px 0px;
    font-size: 15px;
    text-align: center;
    line-height: 44px;
    position: relative;
    display: flex;
    justify-content: center;
}
.bot-line {
    width: 24px;
    height: 3px;
    background: #00ac97;
    border-radius: 2px;
    position: absolute;
    bottom: 0;
}
.contents {
    width: 94%;
    margin-left: 3%;
    background: #ffffff;
    border-radius: 8px;
    margin-top: 10px;
    padding: 0 15px;
    box-sizing: border-box;
    margin-top: 110px;
}
.contens-item {
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
}
.item-title {
    font-size: 16px;
    color: #272929;
}
.item-input {
    border: none;
    text-align: right;
    font-size: 14px;
}
.bottom-btn {
    height: 49px;
    width: 100%;
    /* margin-left: 3%; */
    margin-top: 34px;;
    background: #00ac97;
    color: #fff;
    border-radius: 40px;
    line-height: 49px;
    text-align: center;
    z-index: 1;
    margin-bottom: 15px;
}
.bgm {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
}
.contents2 {
    /* margin-top: 10px; */
    padding: 0 15px;
    margin-top: 60px;
}
.ls-list {
    /* width: 94%;
    margin-left: 3%; */
    background: #fff;
    padding: 0 15px 10px 15px;
    border-radius: 8px;
    margin-bottom: 10px;
}
.item-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    border-bottom: 1px solid #f2f2f2;
}
.wrapper {
    display: flex;
    font-size: 14px;
    color: #333333;
}
.show-two-line {
    overflow:hidden; 

    text-overflow:ellipsis;

    display:-webkit-box; 

    -webkit-box-orient:vertical;

    -webkit-line-clamp:2; 
}
.more {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #FFF;
    padding: 0px 5px;
    color: #00AC97;
}
.clear-input {
    position: absolute;
    top: 10px;
    right: 5px;
}
.search-box {
    width: 100%;
    height: 44px;
    align-items: center;
    position: fixed;
    top: 8px;
    left: 0;
    background: #f5f5f5;
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    box-sizing: border-box;
}
.search-input {
    position: relative;
    background: #fff;
    height: 32px;
    width: 88%;
    display: flex;
    align-items: center;
    border-radius: 4px;
}
.search-input input {
    border: none;
    width: 100%;
    font-size: 14px;
    background: none;
}
.search-input img {
    width: 14px;
    height: 14px;
    margin-left: 7px;
    margin-right: 7px;
}
.search-btn {
    width: 12%;
    font-size: 14px;
    text-align: right;
    color: #00AC97;
    cursor: pointer;
}
.now-pg {
    height: 30px;
    border-radius: 8px;
    border: 1px solid #00AC97;
    font-size: 14px;
    padding: 0 15px;
    line-height: 30px;
}
.sel-item {
    height: 40px;line-height: 40px;
    text-align: center;
    color: #333333;
    background: #E7E8EB;
    padding: 0 15px;
    margin-right: 10px;
    border-radius: 8px;
    margin-top: 10px;
}
.list-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
}
.selbx {
    background: #00AC97;
    color: #fff;
}
/deep/ .van-cell {
    padding: 0;
}
input::-webkit-input-placeholder {
    color: #cacbce;
}
input::-moz-input-placeholder {
    color: #cacbce;
}
input::-ms-input-placeholder {
    color: #cacbce;
}
</style>
